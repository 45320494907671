import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import styled from "styled-components"
import { getBlogUrl } from "../lib/helpers"
// import PortableText from "./portableText"
import { theming } from "@staccx/base"

const PostPreview = ({ className, isSelected = false, location, ...props }) => {
  return (
    <>
      <LinkWrapper isSelected={isSelected} location={location}>
        <AniLink
          fade
          to={getBlogUrl(props.publishedAt, props.slug.current)}
          className={className}
          tabIndex="-1"
        >
          {props.abbreviation ? (
            <>
              <MainTerm location={location} className="MainTerm">
                {props.abbreviation.current}
              </MainTerm>
              <AbbreviationDescription
                location={location}
                className="AbbreviationDescription"
              >
                {props.title}
              </AbbreviationDescription>
            </>
          ) : (
            <MainTerm location={location} className="MainTerm">
              {props.title}
            </MainTerm>
          )}
        </AniLink>
      </LinkWrapper>
      {/* {props._rawExcerpt && (
        <Description>
          <PortableText blocks={props._rawExcerpt} />
        </Description>
      )} */}
    </>
  )
}

const LinkWrapper = styled.div`
  a {
    text-decoration: none;
    display: block;
    opacity: ${p => (p.isSelected ? 1.0 : 0.6)};
    padding: ${theming.spacing.small} 0;
    line-height: 1;
    font-size: ${theming.font.h2}; /* cascades down to children */
    text-align: center;
    color: ${p => (p.location === "index" ? theming.color.white : "inherit")}
    :hover {
      text-decoration: none;
      opacity: 1;
    }
    :focus {
      outline: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  &:not(:last-child) {
    margin-bottom: ${theming.spacing.large};
  }
`

const MainTerm = styled.div`
  font-family: ${theming.fontFamily.heading};
  font-weight: bold;
  font-size: ${theming.font.h2};
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const AbbreviationDescription = styled.div`
  line-height: 1.2;
  font-size: ${theming.font.h3};

  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 0;
  }
`

export default PostPreview
