import { css } from "styled-components"
import { theming } from "@staccx/base"
/* eslint-disable-next-line */
import * as fonts from "./fonts"

export default theming.createGlobal({
  baseReset: theming.cssResets,
  fonts: css`
    a {
      color: inherit;
      text-decoration: underline;
    }
  `
})
