import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { theming } from "@staccx/base"
import { navigate } from "gatsby"
import { getBlogUrl } from "../lib/helpers"
import PostPreview from "./PostPreview"
import useKeyPress from "./useKeyPress"
import {
  // Link,
  Element,
  Events,
  // animateScroll as scroll,
  // scrollSpy,
  scroller
} from "react-scroll"

const SearchResults = ({
  items,
  onChange = () => null,
  isFocus,
  hasResults = () => null,
  className,
  location
}) => {
  const [currentIndex, setCurrentIndex] = useState(null)

  const increment = useKeyPress("ArrowDown")
  const decrement = useKeyPress("ArrowUp")
  const go = useKeyPress("Enter")

  const handleIncrement = () => {
    if (isFocus) {
      if (currentIndex === items.length - 1) {
        console.log("do tab, not increment")
      } else {
        const newIndex =
          currentIndex !== null
            ? Math.min(items.length - 1, currentIndex + 1)
            : 0
        setCurrentIndex(newIndex)
        onChange(items[newIndex].title)
        scroller.scrollTo(items[newIndex].title, {
          duration: 200,
          smooth: true,
          containerId: "results",
          isDynamic: true
        })
      }
    }
  }

  const handleDecrement = () => {
    if (isFocus) {
      if (currentIndex === 0) {
        console.log("do shift-tab, not increment")
      } else {
        const newIndex =
          currentIndex !== null ? Math.max(0, currentIndex - 1) : 0
        setCurrentIndex(newIndex)
        onChange(items[newIndex].title)
        scroller.scrollTo(items[newIndex].title, {
          duration: 200,
          smooth: true,
          containerId: "results",
          isDynamic: true
          // , offset: containerTopPosition * -1
        })
      }
    }
  }

  useEffect(() => {
    // focus changed
  }, [isFocus])

  // When items change, reset selection
  useEffect(() => {
    setCurrentIndex(null)
    hasResults(items.length > 0)
  }, [items])

  useEffect(() => {
    if (decrement && items.length > 0) {
      // on key up or shift-tab:
      handleDecrement()
    } else if (increment && items.length > 0) {
      // on key down or tab:
      handleIncrement()
    }
  }, [increment, decrement])

  useEffect(() => {
    if (currentIndex === null && items.length) {
      navigate(getBlogUrl(items[0].publishedAt, items[0].slug.current))
    }
    if (go && currentIndex != null) {
      navigate(
        getBlogUrl(
          items[currentIndex].publishedAt,
          items[currentIndex].slug.current
        )
      )
    }
  }, [go])

  // --- Begin scroll stuff --- //
  useEffect(() => {
    Events.scrollEvent.register("begin", function() {
      console.log("begin", arguments)
    })

    Events.scrollEvent.register("end", function() {
      console.log("end", arguments)
    })
  }, [])
  useEffect(() => {
    return () => {
      Events.scrollEvent.remove("begin")
      Events.scrollEvent.remove("end")
    }
  })
  // --- End scroll stuff --- //

  return (
    <ScrollBox id="results" className="ResultsScroll">
      {items.length > 0 &&
        items.map((item, index) => (
          <PostPreviewOuterElement
            name={item.title}
            key={`${index}_${item.title}`}
            className="PostPreviewWrapper"
            location={location}
          >
            <PostPreview
              className={className}
              {...item}
              isSelected={index === currentIndex}
              location={location}
            />
          </PostPreviewOuterElement>
        ))}
    </ScrollBox>
  )
}

const ScrollBox = styled(Element)`
  /*
  Start react-scroll-requirement */
  position: relative;
  max-height: 100%;
  overflow-y: auto; /*
  End react-scroll-requirement */
`

const PostPreviewOuterElement = styled(Element)`
  @media (max-width: 768px) {
    ${p =>
      p.isSearchMode &&
      css`
        padding: ${theming.spacing.small} 0;
      `}
  }
`

SearchResults.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
}

export default SearchResults
