import React from "react"
import styled from "styled-components"

const BackArrow = ({ className }) => (
  <SVG
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2 9H20" strokeWidth="2" />
    <path d="M10 1L2 9L10 17" strokeWidth="2" />
  </SVG>
)

const SVG = styled.svg`
  stroke: currentColor;
`

export default BackArrow
