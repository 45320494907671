import React, { useEffect, useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import styled, { css } from "styled-components"
import { theming, Input, Button, useSearch } from "@staccx/base"
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes
} from "../lib/helpers"
import BackArrow from "./icon/BackArrow"

export const toPlainText = (blocks = []) => {
  if (blocks) {
    return (
      blocks
        // loop through each block
        .map(block => {
          // if it's not a text block with children,
          // return nothing
          if (block._type !== "block" || !block.children) {
            return ""
          }
          // loop through the children spans, and join the
          // text strings
          return block.children.map(child => child.text).join("")
        })
        // join the parapgraphs leaving split by two linebreaks
        .join("\n\n")
    )
  }
}

const SearchInput = ({
  posts,
  onInputChange = () => null,
  onInputFocusChange = () => null,
  selectedSearchValue = null,
  hasResults,
  isSearchMode,
  disableSearchMode,
  isSmallScreen,
  className,
  location,
  pageColors = null
}) => {
  const [allItems, setAllItems] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [searchInput, setSearchInput] = useState("")
  const [inputFocus, setInputFocus] = useState(false)

  const [result] = useSearch({
    input: searchInput,
    documents: allItems,
    keys: ["title", "abbreviation.current", "bodyText", "exerptText"]
  })

  const inputRef = React.useRef()

  // Handles changes from the input itself (controlled component):
  const handleInputChange = e => {
    setSearchInput(e.target.value) // -> items
    setSearchValue(e.target.value) // -> input
  }

  const handleBackClick = () => {
    disableSearchMode()
    setSearchInput("")
    setSearchValue("")
  }

  useEffect(() => {
    const items = searchInput && searchInput.length ? result : []
    onInputChange(items)
  }, [result, searchInput])

  const handleSetFocus = newFocus => {
    setInputFocus(newFocus)
  }

  useEffect(() => {
    onInputFocusChange(inputFocus)
    if (inputFocus) {
      inputRef.current.focus()
    }

    // For dev-testing only
    // TODO: remove this
    // setInputFocus(true)
  }, [inputFocus])

  useEffect(() => {
    // Set focus to search field if desktop size:
    setInputFocus(window.innerWidth > 768)
  }, [])

  // Handles when new searchValue comes in based on selected result:
  const handleSearchValueFromSelected = val => {
    val && setSearchValue(val)
  }

  useEffect(() => {
    handleSearchValueFromSelected(selectedSearchValue)
  }, [selectedSearchValue])

  useEffect(() => {
    const postNodes =
      posts || {}
        ? mapEdgesToNodes(posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : []

    const sortedNodes = postNodes
      .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
      .map(item => ({
        ...item,
        bodyText: toPlainText(item._rawBody),
        exerptText: toPlainText(item._rawExcerpt)
      }))

    setAllItems(sortedNodes)
  }, [posts])

  useEffect(() => {
    if (!hasResults && searchValue) {
      trackCustomEvent({
        category: "searchedButNotFound",
        action: "search",
        label: `user searched for ${searchValue}`,
        value: 1
      })
    }
  }, [hasResults])

  return (
    <InputParent
      hasFocus={inputFocus}
      isSearchMode={isSearchMode}
      hasResults={hasResults}
      location={location}
      pageColors={pageColors}
      className="InputParent"
    >
      {isSmallScreen && isSearchMode && (
        <BackButton className="BackButton" onClick={handleBackClick}>
          <StyledBackArrow pageColors={pageColors} />
        </BackButton>
      )}
      <StyledInput
        className={className}
        type="search"
        placeholder="søk her"
        onChange={handleInputChange}
        onKeyDown={e => e.keyCode === 38 && e.preventDefault()}
        ref={inputRef}
        onFocus={() => handleSetFocus(true)}
        onBlur={() => handleSetFocus(false)}
        spellCheck="false"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        value={searchValue}
        hasFocus={inputFocus}
        isSearchMode={isSearchMode}
        location={location}
        pageColors={pageColors}
      />
    </InputParent>
  )
}

export const InputParent = styled.div`
  ${p =>
    p.location === "index"
      ? css`
          position: relative;
          &::after {
            content: "";
            background-color: ${theming.color.white};
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            height: 2px;
            width: 0px;
            transition: width 0ms 200ms, opacity 200ms ease;
            ${p =>
              p.hasFocus &&
              p.hasResults &&
              css`
                opacity: 0.4;
                width: 50%;
                transition: width 500ms ease, opacity 500ms ease;
              `};
          }
        `
      : css`
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: currentColor;
            left: 0;
            bottom: 0;
            opacity: 0.5;
            ${p =>
              p.hasFocus &&
              css`
                opacity: 1;
              `}
          }
        `}

  @media (max-width: 768px) {
    ${p =>
      p.isSearchMode &&
      css`
        padding-bottom: 0;
        ::after {
          background-color: ${p => p.pageColors && p.pageColors[1]};
          ${p =>
            p.isSearchMode &&
            p.hasResults &&
            css`
              width: 100%;
              transition: width 300ms ease, opacity 500ms ease;
            `};
        }
      `}
  }
`

const BackButton = styled(Button)`
  position: absolute;
  min-height: 0;
  height: 100%;
  left: 0;
  padding: ${theming.spacing.small};
  &,
  :hover,
  :focus {
    background-color: transparent;
  }
  z-index: 1;

  svg {
    display: block;
  }
`

const StyledBackArrow = styled(BackArrow)`
  stroke: ${p => (p.pageColors ? p.pageColors[1] : theming.color.white)};
`

export const StyledInput = styled(Input)`
  padding-bottom: ${theming.spacing.tiny};
  input[type="search"] {
    -webkit-appearance: none !important;
  }
  input {
    color: ${p => p.pageColors && p.pageColors[1]};
    caret-color: ${p => p.pageColors && p.pageColors[1]};
    ::placeholder {
      color: ${p => p.pageColors && p.pageColors[1]};
    }
    font-family: ${theming.fontFamily.body};
    font-weight: normal;
    border-radius: 0;
    border: 0;
    line-height: initial;
    &,
    :hover,
    :focus {
      background-color: transparent;
    }
    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
  ${p =>
    p.location === "index"
      ? css`
          input {
            text-align: center;
            ::placeholder {
              color: #fff7;
            }
            font-size: ${theming.font("huge")};
          }
        `
      : css`
          input {
            font-size: ${theming.font("large")};
            padding-left: ${theming.spacing.small};
            padding-right: ${theming.spacing.small};
          }
        `}
  @media (max-width: 768px) {
    font-size: 36px;
    ${p =>
      p.isSearchMode &&
      css`
        input {
          color: ${p => p.pageColors && p.pageColors[1]};
          caret-color: ${p => p.pageColors && p.pageColors[1]};
          text-align: center;
          ::placeholder {
            color: ${p => p.pageColors && p.pageColors[1]};
          }
          text-align: center;
          font-size: 24px;
        }
      `}
  }
`

export default SearchInput
