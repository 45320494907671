import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import openGraphImg from "../img/whatis-finance-open-graph.png"
import * as fonts from "../theme/fonts"

function SEO({ description, lang, meta, keywords, title, ...props }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || (data.site && data.site.description) || ""
        const siteTitle = (data.site && data.site.title) || ""

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={
              title.toLowerCase() !== siteTitle
                ? `${title} | ${siteTitle}`
                : `${siteTitle}`
            }
            meta={[
              {
                name: "description",
                content: metaDescription
              },
              {
                property: "og:title",
                content: title
              },
              {
                property: "og:description",
                content: metaDescription
              },
              {
                property: "og:type",
                content: "website"
              },
              {
                property: "og:url",
                content: "https://whatis.finance/"
              },
              {
                property: "og:image",
                content: `https://whatis.finance${openGraphImg}`
              },
              {
                name: "twitter:card",
                content: "summary"
              },
              {
                name: "twitter:title",
                content: title
              },
              {
                name: "twitter:description",
                content: metaDescription
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", ")
                    }
                  : []
              )
              .concat(meta)}
          >
            <style>
              {`@font-face {
              font-family: "Circe";
              font-weight: 400;
              src: local("Circe Light"), local("Circe-Light"),
              url(${fonts.LightOTF}) format("opentype");
            }
              @font-face {
              font-family: "Circe";
              font-weight: 800;
              src: local("Circe Bold"), local("Circe-Bold"),
              url(${fonts.BoldOTF}) format("opentype");
            }
           `}
            </style>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
    }
  }
`
