import { theming } from "@staccx/base"
// import { SomeComponentVariants } from "./variants/SomeComponent.variants"
import reset from "./reset"

const borderRadius = "4px"
const headerHeight = "70px"

const targetSize = {
  small: "36px",
  normal: "44px"
}

const wrapper = {
  small: "420px",
  medium: "640px",
  mediumPlus: "960px",
  large: "1280px",
  breakout: "640px"
}

const spacing = {
  grid: "20px",
  gridLarge: "20px",
  huge: "96px",
  largePlus: "72px",
  large: "48px",
  mediumPlus: "32px",
  medium: "24px",
  small: "12px",
  tiny: "6px"
}
const color = {
  bg: "#273739",
  bgGray: "#F8F8F8",
  primary: "#D44235",
  primaryWcag: "#D44235",
  primaryDark: "#872A22",
  primaryMediumDark: "#B72E21",
  secondary: "#467EFF",
  gray: "#B8B8B8",
  text: "#ffffff",
  textDark: "#2b2b2b",
  white: "#fff",
  line: "#EBE7E7",
  wcag: "#777",
  disabled: "#B8B8B8",
  warning: "#EB5E55",
  positive: "#467EFF",
  negative: "#EB5E55",
  black: "#000",
  subtleHover: "#F8F8F8",
  blue: "#467EFF",
  red: "#EB5E55",
  orange: "#FFBA30",
  yellow: "#FFF230",
  green: "#66CD73",
  g1: "#6C6C6C",
  g2: "#B8B8B8",
  g3: "#E4E2E2",
  g4: "#F8F8F8",
  g5: "#fff",
  blush: "#FFF1E5",
  pink: "#FCEEED",
  placeholder: "#b8b8b8",
  inputLine: "#dcdcdc",
  link: "#467EFF",
  espen: "313131"
}

const font = {
  h1: "64px",
  h2: "36px",
  h3: "22px",
  h4: "18px",
  h5: "16px",
  h6: "14px",
  base: "16px",
  small: "14px",
  tiny: "12px",
  input: "16px",
  large: "48px",
  giga: "64px",
  huge: "70px",
  lineHeight: "1.5",
  description: "26px"
}

// const fontFamily = {
//   heading: "WigrumMedium",
//   body: "WigrumLight"
// }

// const fontFamily = {
//   heading: "'M Plus 1p'",
//   body: "'M Plus 1p'"
// }

const fontFamily = {
  heading: "Circe",
  body: "Circe"
}

const fontWeight = {
  bold: "800",
  normal: "400"
}

const boxShadow = `0px 3px 10px rgba(0,0,0,0.2)`

const theme = new theming.Theme({
  name: "WhatIsFinance",
  storybookName: "WhatIsFinance",
  font: { ...font },
  fontWeight,
  // webfonts,
  color: { ...color },
  wrapper,
  targetSize,
  borderRadius,
  headerHeight,
  fontFamily,
  spacing,
  boxShadow
}).add(reset)

export default theme
