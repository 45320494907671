import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "../theme/WhatIsFinanceTheme"
import { GlobalStyle } from "@staccx/base"

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
)

export default Layout
