import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import SearchInput from "../components/SearchInput"
import SearchResults from "../components/SearchResults"

// NOTE: isSearchMode is used to set search mode for small screens only

/* TODO: Clean up all places where hasFocus
   is used as a prop, but never used. */

const Search = ({
  posts,
  pageColors,
  location,
  inputFocusCallback = () => null,
  searchModeCallback = () => null,
  resultsCallback = () => null
}) => {
  const [items, setItems] = useState([])
  const [searchVal, setSearchVal] = useState("")
  const [inputFocus, setInputFocus] = useState(null)
  const [hasResults, setHasResults] = useState(null)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(null)

  const handleResultsChange = newVal => {
    setHasResults(newVal)
    resultsCallback(newVal)
  }

  const handleInputChange = newInputVal => {
    setItems(newInputVal)
  }

  const handleFocusChange = newFocus => {
    setInputFocus(newFocus)
    if (isSmallScreen && newFocus) {
      setIsSearchMode(true)
      searchModeCallback(true)
    }
  }

  const disableSearchMode = () => {
    setIsSearchMode(false)
    searchModeCallback(false)
  }

  // Handles when a new value comes in based on selected result:
  const handleSelectedChange = newSelected => {
    setSearchVal(newSelected)
  }

  const handleResize = winSize => {
    if (winSize <= 768) {
      isSmallScreen && setIsSmallScreen(true)
    } else {
      isSmallScreen && setIsSmallScreen(false)
    }
  }

  useEffect(() => {
    // Detect if window resizes
    window.addEventListener("resize", () => handleResize(window.innerWidth))

    // Set isSmallScreen if not desktop size
    if (window.innerWidth <= 768) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  })

  useEffect(() => {
    inputFocusCallback(inputFocus)
  }, [inputFocus])

  return (
    <>
      <SearchInputContainer
        hasResults={items.length}
        hasFocus={inputFocus}
        location={location}
        className="SearchInputContainer"
      >
        <SearchInput
          posts={posts}
          onInputChange={val => handleInputChange(val)}
          onInputFocusChange={focusVal => handleFocusChange(focusVal)}
          selectedSearchValue={searchVal}
          hasResults={hasResults}
          isSearchMode={isSearchMode}
          disableSearchMode={disableSearchMode}
          isSmallScreen={isSmallScreen}
          location={location}
          pageColors={pageColors}
          className="SearchInput"
        />
      </SearchInputContainer>
      <ResultsContainer
        hasResults={items.length}
        location={location}
        pageColors={pageColors}
        className="ResultsContainer"
      >
        <SearchResults
          items={items}
          onChange={val => handleSelectedChange(val)}
          isFocus={inputFocus}
          hasResults={val => handleResultsChange(val)}
          location={location}
          className="SearchResults"
        />
      </ResultsContainer>
    </>
  )
}

const SearchInputContainer = styled.div``

const ResultsContainer = styled.div`
  position: relative;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transform: ${p => p.location === "index" && "translateY(-30px)"};
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 769px) {
    ${p =>
      p.location !== "index" &&
      css`
        background-color: ${p => p.pageColors[1]};
        color: ${p => p.pageColors[0]};
        padding: 12px;
        max-height: 100vh;
        height: 100vh;
      `}
  }

  ${p =>
    p.hasResults &&
    css`
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 300ms 100ms ease, transform 300ms 100ms ease-out,
        pointer-events 300ms 100ms;
    `}

  @media (max-width: 768px) {
    transform: translateY(0);
    padding-top: 0;
    max-height: none;
    color: ${p => p.pageColors[1]};
  }
`

export default Search
